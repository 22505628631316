<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-dark text-light">
          <h3 class="panel-title bold">Export Data</h3>
          <span>Rp. {{ this.currencyFormat(this.totalAmount) }}</span>
        </div>
        <div class="panel-body p-5">
          <div class="row mb-2">
            <div class="col-6 col-md-2">
              <button
                v-on:click="showModal()"
                class="btn btn-primary btn-block"
              >
                <i class="fa fa-filter"></i>
                <span> Filter Data </span>
              </button>
            </div>
            <div class="col-6 col-md-2">
              <button
                v-if="filter.transaction_status == 1 && rows.length > 0"
                type="button"
                v-on:click="downloadExcel()"
                to="/data-transaksi/create"
                class="btn btn-success btn-block"
              >
                <i class="fa fa-file-excel"></i>
                <span> Export Excel</span>
              </button>
              <button
                v-if="filter.transaction_status == 0 && rows.length > 0"
                type="button"
                v-on:click="exportExcel()"
                to="/data-transaksi/create"
                class="btn btn-success btn-block"
              >
                <i
                  v-if="isLoading"
                  class="fa fa-circle-notch"
                  :class="{ 'fa-spin': isLoading }"
                ></i>
                <i v-else class="fa fa-file-excel"></i>
                <span> Export Excel</span>
              </button>
            </div>
          </div>
          <div
            v-if="
              filter.transaction_status == '1' ||
              filter.transaction_status == ''
            "
          >
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              :totalRows="totalRecords"
              :isLoading.sync="isLoading"
              :search-options="{
                enabled: true,
                trigger: 'keyup',
                placeholder: 'Pencarian',
              }"
              :pagination-options="{
                enabled: true,
                perPageDropdownEnabled: true,
                perPageDropdown: [10, 20, 30, 40, 50, 100],
                dropdownAllowAll: false,
                position: 'bottom',
                rowsPerPageLabel: '',
                nextLabel: '',
                prevLabel: '',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: '',
              }"
              :rows="rows"
              :columns="columnsA"
            >
              <div slot="emptystate" class="text-center">
                {{ tableInfo }}
              </div>
              <div slot="table-actions">
                <div style="display: flex">
                  <button
                    type="button"
                    v-on:click="fetchData()"
                    class="btn btn-default mr-2"
                  >
                    <i
                      class="fa fa-sync"
                      :class="{ 'fa-spin': this.isLoading }"
                    ></i>
                  </button>
                </div>
              </div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'status'">
                  <button
                    class="btn btn-sm btn-circle btn-icon btn-success m-r-5"
                    :class="{
                      'btn-grey': props.row.transaction_status == '0',
                    }"
                    :disabled="
                      props.row.transaction_status == '1' &&
                      userLevel != 'admin'
                    "
                  >
                    <i
                      class="fa fa-check"
                      :class="{
                        'fa-exclamation': props.row.transaction_status == '0',
                      }"
                    ></i>
                  </button>
                </span>
                <span v-if="props.column.field == 'action'">
                  <div>
                    <router-link
                      :to="'/data-transaksi/detail/' + props.row.transaction_id"
                      class="btn btn-circle btn-icon btn-success m-r-5"
                    >
                      <i class="fa fa-search"></i>
                    </router-link>
                  </div>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
          <div v-else>
            <vue-good-table
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-search="onSearch"
              :totalRows="totalRecords"
              :isLoading.sync="isLoading"
              :search-options="{
                enabled: true,
                trigger: 'keyup',
                placeholder: 'Pencarian',
              }"
              :pagination-options="{
                enabled: true,
                perPageDropdownEnabled: true,
                perPageDropdown: [10, 20, 30, 40, 50, 100],
                dropdownAllowAll: false,
                position: 'bottom',
                rowsPerPageLabel: '',
                nextLabel: '',
                prevLabel: '',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: '',
              }"
              :rows="rows"
              :columns="columnsB"
            >
              <div slot="emptystate" class="text-center">
                {{ tableInfo }}
              </div>
              <div slot="table-actions">
                <div style="display: flex">
                  <button
                    type="button"
                    v-on:click="fetchData()"
                    class="btn btn-default mr-2"
                  >
                    <i
                      class="fa fa-sync"
                      :class="{ 'fa-spin': this.isLoading }"
                    ></i>
                  </button>
                </div>
              </div>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'status'">
                  <button
                    class="btn btn-sm btn-circle btn-icon btn-success m-r-5"
                    :class="{
                      'btn-grey': props.row.transaction_status == '0',
                    }"
                  >
                    <i
                      class="fa fa-check"
                      :class="{
                        'fa-exclamation': props.row.transaction_status == '0',
                      }"
                    ></i>
                  </button>
                </span>
                <span v-if="props.column.field == 'action'">
                  <div>
                    <a
                      :href="'/data-pelanggan/detail/' + props.row.customer_id"
                      class="btn btn-circle btn-icon btn-success m-r-5"
                      target="_blank"
                    >
                      <i class="fa fa-search"></i>
                    </a>
                  </div>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="modalVisible" @hide="beforeModalClose">
      <template #modal-header>
        <h5 class="modal-title">Filter Data</h5>
        <button
          type="button"
          @click="closeModal"
          class="btn btn-danger btn-sm btn-icon btn-circle"
          data-dismiss="modal"
          aria-hidden="true"
        >
          ×
        </button>
      </template>
      <div>
        <div class="row mb-2">
          <div class="col-4 mb-1" v-if="userLevel == 'admin'">
            <div
              class="checkbox checkbox-css p-4 bg-blue-transparent-2"
              style="border-radius: 5px"
            >
              <input
                type="checkbox"
                id="checkInstansi"
                :checked="parseInt(show.institutions)"
                v-on:click="showFilter(1)"
              />
              <label for="checkInstansi">Instansi</label>
            </div>
          </div>
          <div class="col-4 mb-1" v-if="userLevel == 'admin'">
            <div
              class="checkbox checkbox-css p-4 bg-blue-transparent-2"
              style="border-radius: 5px"
            >
              <input
                type="checkbox"
                id="checkDistric"
                :checked="parseInt(show.district)"
                v-on:click="showFilter(2)"
              />
              <label for="checkDistric">Kecamatan</label>
            </div>
          </div>
          <div class="col-4 mb-1" v-if="userLevel == 'admin'">
            <div
              class="checkbox checkbox-css p-4 bg-blue-transparent-2"
              style="border-radius: 5px"
            >
              <input
                type="checkbox"
                id="checkVillage"
                :checked="parseInt(show.village)"
                v-on:click="showFilter(3)"
              />
              <label for="checkVillage">Kelurahan</label>
            </div>
          </div>
          <div class="col-4 mb-1">
            <div
              class="checkbox checkbox-css p-4 bg-blue-transparent-2"
              style="border-radius: 5px"
            >
              <input
                type="checkbox"
                id="checkYear"
                :checked="parseInt(show.year)"
                v-on:click="showFilter(4)"
              />
              <label for="checkYear">Tahun</label>
            </div>
          </div>
          <div class="col-4 mb-1">
            <div
              class="checkbox checkbox-css p-4 bg-blue-transparent-2"
              style="border-radius: 5px"
            >
              <input
                type="checkbox"
                id="checkMonth"
                :checked="parseInt(show.month)"
                v-on:click="showFilter(5)"
              />
              <label for="checkMonth">Bulan</label>
            </div>
          </div>
          <div class="col-4 mb-1" v-if="userLevel == 'admin'">
            <div
              class="checkbox checkbox-css p-4 bg-blue-transparent-2"
              style="border-radius: 5px"
            >
              <input
                type="checkbox"
                id="checkDate"
                :checked="parseInt(show.date)"
                v-on:click="showFilter(6)"
              />
              <label for="checkDate">Tanggal</label>
            </div>
          </div>
          <div class="col-4 mb-1">
            <div
              class="checkbox checkbox-css p-4 bg-blue-transparent-2"
              style="border-radius: 5px"
            >
              <input
                type="checkbox"
                id="checkTransactionStatus"
                :checked="parseInt(show.transaction_status)"
                v-on:click="showFilter(7)"
              />
              <label for="checkTransactionStatus">Status Bayar</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12" v-if="show.district">
            <select
              v-model="filter.district"
              v-on:change="fetchVillages($event)"
              class="form-control mb-2"
              style="text-transform: uppercase"
            >
              <option value="" style="display: none">Pilih Kecamatan</option>
              <option
                v-for="districts in districtList"
                :value="districts.id_kec"
                :key="districts.id_kec"
                style="text-transform: uppercase"
              >
                {{ districts.nama_kecamatan }}
              </option>
            </select>
          </div>
          <div class="col-12" v-if="show.village">
            <select
              v-model="filter.village"
              class="form-control mb-2"
              style="text-transform: uppercase"
            >
              <option value="" style="display: none">Pilih Kelurahan</option>
              <option
                v-for="villages in villageList"
                :value="villages.id_kel"
                :key="villages.id_kel"
                style="text-transform: uppercase"
              >
                {{ villages.nama_kelurahan }}
              </option>
            </select>
          </div>
          <div class="col-12" v-if="show.institutions">
            <select
              v-model="filter.institutions"
              class="form-control mb-2"
              style="text-transform: uppercase"
            >
              <option value="" style="display: none; text-transform: uppercase">
                Pilih Instansi
              </option>
              <option
                v-for="instansi in instansiList"
                :key="instansi.government_agency_id"
                :value="instansi.government_agency_id"
              >
                {{ instansi.government_agency_name }}
              </option>
            </select>
          </div>
          <div class="col-12" v-if="show.year">
            <select
              v-model="filter.year"
              v-on:change="filter.month = ''"
              class="form-control mb-2"
              style="text-transform: uppercase"
            >
              <option value="" style="display: none">Pilih Tahun</option>
              <option value="2024">2024</option>
              <option value="2023">2023</option>
            </select>
          </div>
          <div class="col-12" v-if="show.month">
            <select
              v-model="filter.month"
              v-if="filter.year"
              class="form-control mb-2"
              style="text-transform: uppercase"
            >
              <option value="" style="display: none; text-transform: uppercase">
                Pilih Bulan
              </option>
              <option value="01">Januari</option>
              <option value="02">Februari</option>
              <option value="03">Maret</option>
              <option value="04">April</option>
              <option value="05">Mei</option>
              <option value="06">Juni</option>
              <option value="07">Juli</option>
              <option value="08">Agustus</option>
              <option value="09">September</option>
              <option value="10">Oktober</option>
              <option value="11">November</option>
              <option value="12">Desember</option>
            </select>
          </div>
          <div class="col-12" v-if="show.date">
            <input
              v-model="filter.date"
              type="date"
              class="form-control mb-2"
            />
          </div>
          <div class="col-12" v-if="show.transaction_status">
            <select
              v-model="filter.transaction_status"
              v-on:change="resetRows()"
              class="form-control mb-2"
            >
              <option value="" style="display: none">Pilih Status Bayar</option>
              <option value="1">SUDAH BAYAR</option>
              <option value="0">BELUM BAYAR</option>
            </select>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <button
          v-if="
            filter.date ||
            filter.district ||
            filter.village ||
            filter.institutions ||
            filter.month ||
            filter.year
          "
          v-on:click="resetFilter()"
          class="btn btn-danger"
        >
          <i class="fa fa-times"></i>
          <span> Reset Filter </span>
        </button>
        <button
          v-if="
            filter.date ||
            filter.district ||
            filter.village ||
            filter.institutions ||
            filter.month ||
            filter.year
          "
          @click="fetchData()"
          class="btn btn-success"
          variant="primary"
        >
          <i
            v-if="isLoading"
            class="fa fa-circle-notch"
            :class="{ 'fa-spin': isLoading }"
          ></i>
          <i v-else class="fa fa-search"></i> Filter Data
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import sipData from "../../plugins/sipData";
import moment from "moment";
const ExcelJS = require("exceljs");

export default {
  mixins: [sipData],
  data() {
    return {
      columnsA: [
        {
          label: "Tanggal SKRD",
          field: "transaction_date",
          sortable: false,
          formatFn: this.datesmallFormat,
          thClass: "text-center text-nowrap valign-middle",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Tanggal SSRD",
          field: "transaction_confirm",
          sortable: false,
          formatFn: this.datesmallFormat,
          thClass: "text-center text-nowrap valign-middle",
          tdClass: "text-center text-wrap",
        },
        {
          label: "NPWRD",
          field: "npwrd",
          sortable: false,
          thClass: "text-center text-nowrap valign-middle",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Nama",
          field: "customer_name",
          sortable: false,
          thClass: "text-center text-nowrap valign-middle",
          tdClass: "text-wrap",
        },
        {
          label: "Alamat",
          field: "customer_address",
          sortable: false,
          thClass: "text-center text-nowrap valign-middle",
          tdClass: "text-wrap",
        },
        {
          label: "Jenis Kegiatan",
          field: "category_name",
          sortable: false,
          thClass: "text-center text-nowrap valign-middle",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Satuan",
          field: "cost",
          sortable: false,
          formatFn: this.currencyFormat,
          thClass: "text-center text-nowrap valign-middle",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Jumlah Bayar",
          field: "payment_amount",
          sortable: false,
          formatFn: this.currencyFormat,
          thClass: "text-center text-wrap valign-middle",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Periode",
          field: "additional_details",
          sortable: false,
          thClass: "text-center text-nowrap valign-middle",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Pembayaran",
          field: "payment_method",
          sortable: false,
          thClass: "text-center text-wrap valign-middle",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Status",
          field: "status",
          sortable: false,
          thClass: "text-center text-wrap valign-middle",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Diinput Oleh",
          field: "created_name",
          sortable: false,
          thClass: "text-center text-wrap valign-middle",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Aksi",
          field: "action",
          sortable: false,
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse valign-middle text-nowrap",
          thClass: "text-center text-nowrap valign-middle",
        },
      ],
      columnsB: [
        {
          label: "NPWRD",
          field: "npwrd",
          sortable: false,
          thClass: "text-center text-nowrap valign-middle",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Nama",
          field: "customer_name",
          sortable: false,
          thClass: "text-center text-nowrap valign-middle",
          tdClass: "text-wrap",
        },
        {
          label: "Alamat",
          field: "customer_address",
          sortable: false,
          thClass: "text-center text-nowrap valign-middle",
          tdClass: "text-wrap",
        },
        {
          label: "Jenis Kegiatan",
          field: "category_name",
          sortable: false,
          thClass: "text-center text-nowrap valign-middle",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Satuan",
          field: "cost",
          sortable: false,
          formatFn: this.currencyFormat,
          thClass: "text-center text-nowrap valign-middle",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Aksi",
          field: "action",
          sortable: false,
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse valign-middle text-nowrap",
          thClass: "text-center text-nowrap valign-middle",
        },
      ],
      rows: [],
      tableInfo: "Loading data",
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: 10,
      },
      filter: {
        date: "",
        district: "",
        village: "",
        institutions: "",
        month: "",
        year: "",
        transaction_status: 1,
      },
      show: {
        date: 0,
        district: 0,
        village: 0,
        institutions: 0,
        month: 0,
        year: 0,
        transaction_status: 0,
      },
      searchTerm: "",
      timeoutSearch: null,
      loadDPT: false,
      totalAmount: 0,
      districtList: [],
      villageList: [],
      instansiList: [],
      modalVisible: false,
      conditionMet: false,
      institution_name: "",
      district_name: "",
      village_name: "",
    };
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    this.fetchDistricts();
    this.fetchInstansi();
    this.tableInfo = "Masukkan Filter Data";
    this.rows = [];
  },
  methods: {
    onSearch(params) {
      clearTimeout(this.timeoutSearch);
      this.timeoutSearch = setTimeout(() => {
        this.searchTerm = params.searchTerm;
        if (this.searchTerm) {
          this.fetchData();
        } else {
          this.rows = [];
          this.totalRecords = 0;
          this.totalAmount = 0;
        }
      }, 500);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.fetchData();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.fetchData();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          },
        ],
      });
      this.fetchData();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.fetchData();
    },

    fetchData() {
      this.isLoading = true;

      const selectedAgency = this.instansiList.find(
        (instansi) => instansi.government_agency_id === this.filter.institutions
      );
      const selectedDistrict = this.districtList.find(
        (district) => district.id_kec === this.filter.district
      );
      const selectedVillage = this.villageList.find(
        (village) => village.id_kel === this.filter.village
      );

      if (selectedAgency) {
        this.institution_name = selectedAgency.government_agency_name;
      }
      if (selectedDistrict) {
        this.district_name = selectedDistrict.nama_kecamatan;
      }
      if (selectedVillage) {
        this.village_name = selectedVillage.nama_kelurahan;
      }

      axios
        .get("/v1/filter/", {
          headers: {
            token: this.userToken,
          },
          params: {
            search: this.searchTerm,
            page: this.serverParams.page,
            limit: this.serverParams.perPage,
            date: this.filter.date,
            district: this.filter.district,
            village: this.filter.village,
            institutions: this.filter.institutions,
            month: this.filter.month,
            year: this.filter.year,
            transaction_status: this.filter.transaction_status,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.totalRecords = response.data.total;
          this.totalAmount = response.data.total_amount;
          if (this.totalRecords <= 0) {
            this.tableInfo = "Empty data";
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    indonesianFormat(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    datesmallFormat(dateTime) {
      if (dateTime) {
        return moment(dateTime).format("DD-MM-Y");
      } else {
        return "-";
      }
    },
    currencyFormat(value) {
      if (!value) return "";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    downloadExcel() {
      this.isLoading = true;
      axios
        .get("/v1/filter/export", {
          headers: {
            token: this.userToken,
          },
          params: {
            search: this.searchTerm,
            page: this.serverParams.page,
            limit: this.serverParams.perPage,
            date: this.filter.date,
            district: this.filter.district,
            village: this.filter.village,
            institutions: this.filter.institutions,
            month: this.filter.month,
            year: this.filter.year,
            transaction_status: this.filter.transaction_status,
          },
        })
        .then((response) => {
          let data = response.data.data;

          let dataArray = data.map((item, index) => ({
            index: index + 1,
            transaction_date:
              this.indonesianFormat(item.transaction_date).toUpperCase() || " ",
            transaction_confirm:
              this.indonesianFormat(item.transaction_confirm).toUpperCase() ||
              " ",
            npwrd: parseInt(item.npwrd) || " ",
            customer_name: item.customer_name.toUpperCase() || " ",
            customer_address: item.customer_address.toUpperCase() || " ",
            district_name: item.district_name.toUpperCase() || " ",
            village_name: item.village_name.toUpperCase() || " ",
            hamlet: item.hamlet.toString() || " ",
            neighbourhood: item.neighbourhood.toString() || " ",
            category_name: item.category_name.toUpperCase() || " ",
            cost: parseInt(item.cost) || " ",
            additional_details: item.additional_details.toUpperCase() || " ",
            payment_method: item.payment_method.toUpperCase() || " ",
            payment_amount: parseInt(item.payment_amount) || " ",
            created_name: item.created_name.toUpperCase() || " ",
            transaction_code: item.transaction_code || " ",
          }));

          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("Data Transaksi");

          let tableHeaderName = "";
          if (this.institution_name) {
            tableHeaderName +=
              "DATA TRANSAKSI PEGAWAI DI " +
              this.institution_name.toUpperCase();
          }
          if (this.district_name) {
            tableHeaderName =
              "DATA TRANSAKSI KECAMATAN " + this.district_name.toUpperCase();
          }
          if (this.village_name) {
            tableHeaderName += " KELURAHAN " + this.village_name.toUpperCase();
          }

          worksheet.mergeCells("A1:Q1");
          const headerCell = worksheet.getCell("A1");
          headerCell.value = tableHeaderName;
          headerCell.alignment = { horizontal: "left", vertical: "middle" };

          const columnWidths = [
            { header: "NO.", key: "index", width: 5 },
            { header: "TANGGAL SKRD", key: "transaction_date", width: 20 },
            { header: "TANGGAL SSRD", key: "transaction_confirm", width: 20 },
            { header: "NPWRD", key: "npwrd", width: 15 },
            { header: "NAMA", key: "customer_name", width: 30 },
            {
              header: "ALAMAT",
              key: "customer_address",
              width: 40,
            },
            { header: "KECAMATAN", key: "district_name", width: 20 },
            { header: "KELURAHAN", key: "village_name", width: 20 },
            { header: "RW", key: "hamlet", width: 7 },
            { header: "RT", key: "neighbourhood", width: 7 },
            {
              header: "JENIS KEGIATAN",
              key: "category_name",
              width: 35,
            },
            { header: "SATUAN", key: "cost", width: 15 },
            { header: "PERIODE", key: "additional_details", width: 27 },
            { header: "METODE PEMBAYARAN", key: "payment_method", width: 15 },
            { header: "JUMLAH BAYAR", key: "payment_amount", width: 18 },
            { header: "NAMA PENGINPUT", key: "created_name", width: 18 },
            {
              header: "KODE TRANSAKSI TRANSFER",
              key: "transaction_code",
              width: 25,
            },
          ];

          // worksheet.addRow([]);

          const columnHeadersRow = worksheet.addRow(
            columnWidths.map((col) => col.header)
          );

          columnHeadersRow.eachCell((cell) => {
            cell.font = { bold: true };
            cell.alignment = {
              horizontal: "center",
              vertical: "middle",
              wrapText: true,
            };
          });

          worksheet.columns = columnWidths.map((col) => ({
            key: col.key,
            width: col.width,
          }));

          // worksheet.columns = columnWidths;

          dataArray.forEach((item) => {
            worksheet.addRow(item);
          });

          const totalPaymentAmount = dataArray.reduce(
            (total, item) => total + (parseInt(item.payment_amount) || 0),
            0
          );

          worksheet.addRow({
            payment_amount: totalPaymentAmount,
          });

          const columnCenter = [
            "npwrd",
            "transaction_date",
            "transaction_confirm",
            "payment_method",
            "category_name",
            "additional_details",
            "district_name",
            "village_name",
            "hamlet",
            "neighbourhood",
            "transaction_code",
            "created_name",
          ];
          columnCenter.forEach((columnName) => {
            const column = worksheet.getColumn(columnName);
            column.alignment = {
              horizontal: "center",
              vertical: "middle",
              wrapText: true,
            };
          });

          const columnRight = ["index", "cost", "payment_amount"];
          columnRight.forEach((columnName) => {
            const column = worksheet.getColumn(columnName);
            column.alignment = {
              horizontal: "right",
              vertical: "middle",
              wrapText: true,
            };
          });

          const columnLeft = ["customer_name", "customer_address"];
          columnLeft.forEach((columnName) => {
            const column = worksheet.getColumn(columnName);
            column.alignment = {
              horizontal: "left",
              vertical: "middle",
              wrapText: true,
            };
          });

          const headerRow = worksheet.getRow(1);
          headerRow.eachCell((cell) => {
            cell.font = { bold: true };
            cell.alignment = {
              horizontal: "center",
              vertical: "middle",
              wrapText: true,
            };
          });

          const borderStyle = {
            style: "thin",
            color: { argb: "000000" },
          };

          worksheet.eachRow((row) => {
            row.eachCell((cell) => {
              cell.border = {
                top: borderStyle,
                left: borderStyle,
                bottom: borderStyle,
                right: borderStyle,
              };
            });
          });

          const lastRow = worksheet.lastRow;
          lastRow.eachCell({ includeEmpty: true }, (cell) => {
            cell.font = { bold: true };
            cell.alignment = { horizontal: "right", vertical: "middle" };
            cell.border = {
              top: borderStyle,
              left: borderStyle,
              bottom: borderStyle,
              right: borderStyle,
            };
          });
          worksheet.mergeCells(`A${lastRow.number}:M${lastRow.number}`);

          worksheet.getCell(`L${lastRow.number}`).value = "JUMLAH TOTAL BAYAR";
          worksheet.getCell(`L${lastRow.number}`).alignment = {
            horizontal: "right",
            vertical: "middle",
          };

          return workbook.xlsx.writeBuffer();
        })
        .then((excelBuffer) => {
          this.saveAsExcelFile(excelBuffer, "data-transaksi.xlsx");
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    exportExcel() {
      this.isLoading = true;
      axios
        .get("/v1/filter/export", {
          headers: {
            token: this.userToken,
          },
          params: {
            search: this.searchTerm,
            page: this.serverParams.page,
            limit: this.serverParams.perPage,
            date: this.filter.date,
            district: this.filter.district,
            village: this.filter.village,
            institutions: this.filter.institutions,
            month: this.filter.month,
            year: this.filter.year,
            transaction_status: this.filter.transaction_status,
          },
        })
        .then((response) => {
          let data = response.data.data;

          let dataArray = data.map((item, index) => ({
            index: index + 1,
            npwrd: parseInt(item.npwrd) || " ",
            customer_name: item.customer_name.toUpperCase() || " ",
            customer_address: item.customer_address.toUpperCase() || " ",
            district_name: item.district_name.toUpperCase() || " ",
            village_name: item.village_name.toUpperCase() || " ",
            hamlet: item.hamlet.toString() || " ",
            neighbourhood: item.neighbourhood.toString() || " ",
            category_name: item.category_name.toUpperCase() || " ",
            cost: parseInt(item.cost) || " ",
          }));

          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("Data Transaksi");

          let tableHeaderName = "";
          if (this.institution_name) {
            tableHeaderName += this.institution_name;
          }
          if (this.district_name) {
            tableHeaderName = "KECAMATAN " + this.district_name.toUpperCase();
          }
          if (this.village_name) {
            tableHeaderName += " KELURAHAN " + this.village_name.toUpperCase();
          }

          worksheet.mergeCells("A1:J1");
          worksheet.getCell("A1").value =
            "DAFTAR PEGAWAI YANG BELUM MELAKUKAN PEMBAYARAN DI " +
            tableHeaderName;

          // Baris kedua untuk menetapkan lebar kolom
          const columnWidths = [
            { header: "NO.", key: "index", width: 5 },
            { header: "NPWRD", key: "npwrd", width: 15 },
            { header: "NAMA", key: "customer_name", width: 30 },
            {
              header: "ALAMAT",
              key: "customer_address",
              width: 40,
            },
            { header: "KECAMATAN", key: "district_name", width: 20 },
            { header: "KELURAHAN", key: "village_name", width: 20 },
            { header: "RW", key: "hamlet", width: 7 },
            { header: "RT", key: "neighbourhood", width: 7 },
            {
              header: "JENIS KEGIATAN",
              key: "category_name",
              width: 35,
            },
            { header: "SATUAN", key: "cost", width: 15 },
          ];

          // Menyisipkan baris kosong setelah deskripsi
          // worksheet.addRow([]);

          // Menambahkan nilai lebar kolom pada baris kedua
          const columnHeadersRow = worksheet.addRow(
            columnWidths.map((col) => col.header)
          );

          // Mengatur properti style untuk baris yang berisi lebar kolom
          columnHeadersRow.eachCell((cell) => {
            cell.font = { bold: true };
            cell.alignment = {
              horizontal: "center",
              vertical: "middle",
              wrapText: true,
            };
          });

          // Mengatur lebar kolom sesuai dengan nilai yang sudah ditetapkan
          worksheet.columns = columnWidths.map((col) => ({
            key: col.key,
            width: col.width,
          }));

          dataArray.forEach((item) => {
            worksheet.addRow(item);
          });

          const columnCenter = [
            "npwrd",
            "category_name",
            "district_name",
            "village_name",
            "hamlet",
            "neighbourhood",
          ];
          columnCenter.forEach((columnName) => {
            const column = worksheet.getColumn(columnName);
            column.alignment = {
              horizontal: "center",
              vertical: "middle",
              wrapText: true,
            };
          });

          const columnRight = ["index", "cost"];
          columnRight.forEach((columnName) => {
            const column = worksheet.getColumn(columnName);
            column.alignment = {
              horizontal: "right",
              vertical: "middle",
              wrapText: true,
            };
          });

          const columnLeft = ["customer_name", "customer_address"];
          columnLeft.forEach((columnName) => {
            const column = worksheet.getColumn(columnName);
            column.alignment = {
              horizontal: "left",
              vertical: "middle",
              wrapText: true,
            };
          });

          const headerRow = worksheet.getRow(1);
          headerRow.eachCell((cell) => {
            cell.font = { bold: true };
            cell.alignment = {
              horizontal: "center",
              vertical: "middle",
              wrapText: true,
            };
          });

          const borderStyle = {
            style: "thin",
            color: { argb: "000000" },
          };

          worksheet.eachRow((row) => {
            row.eachCell((cell) => {
              cell.border = {
                top: borderStyle,
                left: borderStyle,
                bottom: borderStyle,
                right: borderStyle,
              };
            });
          });

          return workbook.xlsx.writeBuffer();
        })
        .then((excelBuffer) => {
          this.saveAsExcelFile(excelBuffer, "data-transaksi.xlsx");
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    saveAsExcelFile(buffer, filename) {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      }
    },
    fetchDistricts() {
      axios
        .get("/v1/daerah/kecamatan/7271", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.districtList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchVillages(event) {
      this.filter.village = "";
      axios
        .get("/v1/daerah/kelurahan/" + event.target.value, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.villageList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchInstansi() {
      axios
        .get("/v1/government_agencies", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.instansiList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    showModal() {
      this.modalVisible = true;
      this.conditionMet = false;
    },
    closeModal() {
      this.modalVisible = false;
      this.conditionMet = true;
    },
    beforeModalClose(evt) {
      if (!this.conditionMet) {
        evt.preventDefault();
      }
    },
    resetRows() {
      this.rows = [];
    },
    resetFilter() {
      for (let key in this.filter) {
        this.filter[key] = "";
      }
      for (let key in this.show) {
        this.show[key] = 0;
      }
      this.searchTerm = "";
      this.serverParams.page = 1;
      this.serverParams.perPage = 10;
      this.filter.transaction_status = 1;
      this.rows = [];
    },
    showFilter(param) {
      if (param == 1) {
        if (this.show.institutions == 0) {
          this.show.institutions = 1;
        } else {
          this.show.institutions = 0;
          this.filter.institutions = "";
          this.institution_name = "";
        }
      }
      if (param == 2) {
        if (this.show.district == 0) {
          this.show.district = 1;
        } else {
          this.show.district = 0;
          this.show.village = 0;
          this.filter.district = "";
          this.filter.village = "";
          this.district_name = "";
          this.village_name = "";
        }
      }
      if (param == 3) {
        if (this.show.village == 0) {
          this.show.district = 1;
          this.show.village = 1;
        } else {
          this.show.village = 0;
          this.filter.village = "";
          this.village_name = "";
        }
      }
      if (param == 4) {
        if (this.show.year == 0) {
          this.show.year = 1;
        } else {
          this.show.year = 0;
          this.show.month = 0;
          this.filter.year = "";
          this.filter.month = "";
        }
      }
      if (param == 5) {
        if (this.show.month == 0) {
          this.show.year = 1;
          this.show.month = 1;
        } else {
          this.show.month = 0;
          this.filter.month = "";
        }
      }
      if (param == 6) {
        if (this.show.date == 0) {
          this.show.date = 1;
        } else {
          this.show.date = 0;
          this.filter.date = "";
        }
      }
      if (param == 7) {
        if (this.show.transaction_status == 0) {
          this.show.transaction_status = 1;
          this.rows = [];
        } else {
          this.show.transaction_status = 0;
          this.filter.transaction_status = 1;
          this.rows = [];
        }
      }
    },
  },
};
</script>